.nominee-box {
  border: 1px solid blue;
  width: 279px !important;
}
.nominee-modal {
  border: 1px solid blue;
  border-radius: 1px;
}
.nominee-modal-Box {
  border-radius: 5%;
}
.nominee-scroll {
  height: 'calc(100vh - 200px)' !important;
  overflow-y: 'auto' !important;
}
.nominee-form {
  width: 290px !important;
}
.nominee-list-modal {
  background-color: transparent !important;
}
.nominee-edit input {
  &:hover + fieldset,
  &:focus + fieldset {
    border: 1px solid black !important;
  }
  & + fieldset {
    border: 1px solid black !important;
  }
}
.relatioship-selectbox {
  border: 1px solid black !important;
  border-radius: 4px;
}
.relatioship-selectbox :focus {
  border: 1px solid black !important;
}
