.dashboard-card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.w-280px,
.cal-payment-screen {
  width: 280px !important;
}

.dashboard-policy-card-header {
  .next-arrow {
    transform: rotate(180deg) !important;
  }
}

.dashboard-policy-cost-container {
  .header-border {
    width: 20px;
    position: relative;
    top: -2px;
  }
}

.policy-calender-container {
  border-radius: 6px;
}
.payment-options {
  background-color: #f2f2f2;
}
.paid-date {
  background-color: #f2f2f2;
  border-radius: 6px;
}
.finance-my-premium {
  background-color: #f2f2f2;
  border-radius: 15px;
}
.line {
  border-right-width: 5px !important;
  border: 1px solid black;
}
.calendar_row {
  line-height: 0 !important;
}
.claendar-image {
  border: 1px solid #d7d7d7;
  margin-left: 4%;
  object-fit: contain;
}
