.common-style {
  background-color: white !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.api-modal-container {
  min-width: 20vw;
  min-height: 10vh;
  border-radius: 10px;
  padding: 30px;
}
