.address {
  width: 280px;
}
.manage-permanent-address {
  background-color: #f2f2f2;
  border-radius: 6px;
}
.address-modal{
    border-radius: 6px;
}
