.need-money {
  width: 280px;
}
.my-options {
  width: 280px;
  position: relative;
  border: 1px solid #d7d7d7;
  background-color: #f2f2f2;
}
.round-icon {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20;
  height: 10;
  border-radius: 50%;
}
.recommend-select {
  width: 290px;
  border: 1px solid #d7d7d7;
  position: relative;
}
.submit-loan {
  transform: rotate(-180deg);
}

.recommend-icon-number {
  position: absolute;
  left: 89%;
  transform: translate(-30%, -220%);
}
.two {
  top: 32%;
}
.one {
  top: 53% !important;
}
.three {
  top: 7%;
}
.icon-number {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
}
