.updateMobile-main {
  width: 280px !important;
  height: 100%;
}

.greencolor {
  color: #63a103;
}
.redcolor {
  color: #d9001b;
}
.assign-policy {
  width: 290px !important;
}
.permanent-address {
  width: 279px !important;
  height: 224px;
  background-color: #f2f2f2;
  box-shadow: none !important;
}
.address-modal {
  border-radius: 5%;
  border: 1px solid #0051ba;
}
.update-mobile-content {
  background-color: #f2f2f2;
  width: 280px;
}
.image-upload-modal {
  transform: rotate(10deg);
}
