.case1 {
  width: 294px;
}
.modal {
  width: 350px;
}
.instant-verification{
  background-color: #d7d7d7;
}
.relationship-selectbox {
  border: 1px solid  #0051ba !important;
  border-radius: 4px;
}
.relationship-selectbox :focus {
  border: 1px solid  #0051ba !important;
}