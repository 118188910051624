/* .personaldetails {
  width: 280px;
} */
.personal-address {
  width: 205px;
}
.personalscreenaddress {
  width: 280px;
}
.personalscreendetails {
  width: 280px;
}
.bankid {
  width: 280px;
}
.bank-documents {
  width: 280px;
}
.account-details {
  width: 161px;
}
