.form {
  width: 290px;
}
.pan-input {
  width: 294px !important;
  height: 48px !important;
}
.mobile-pin {
  width: 60px !important;
  height: 48px !important;
}
.mobile-number {
  width: 225px !important;
  height: 48px !important;
}
.date-month-input {
  width: 63px !important;
  height: 48px !important;
}
.signup-year-input {
  width: 102px !important;
  height: 48px !important;
}
