.payment-card {
  width: 372px !important;
}
.payment-card-success {
  width: 279px !important;
}
.green-tick {
  position: absolute;
  margin-left: 2%;
  margin-top: 2%;
}
.circle {
  fill: none;
  stroke: black;
  stroke-width: 2;
}
