@import '../../../../app/variables.scss';
.form-input-container {
  &.hidden * :not(.error-text) {
    display: none !important;
  }
  input {
    font-size: 16px !important;
  }
  fieldset {
    border-radius: 4px;
  }

  .hide-count-button {
    input {
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
      }
    }
  }

  .normal-border input {
    &:hover + fieldset,
    &:focus + fieldset {
      border: 1px solid $secondaryMain !important;
    }
    & + fieldset {
      border: 1px solid $primaryMain !important;
    }
  }
}
