@font-face {
  font-family: "Quicksand";
  src: url(../assets/fonts/QuicksandVariable/Quicksand-VariableFont_Weight.ttf);
}
@import "./variables.scss";

* {
  font-family: "Quicksand" !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a {
  text-decoration: none;
}

.h-100 {
  height: 100% !important;
}

.custom-button {
  background-color: transparent !important;
}
.right-arrow {
  transform: rotate(180deg);
}

.otp-input-container {
  justify-content: space-between;
  & > div {
    width: 46px !important;
    height: 52px !important;
    & > input {
      width: 100% !important;
      height: 100% !important;
      border: 1px solid $primaryMain !important;
      border-radius: 4px;
      margin-left: 4px;
      outline: none !important;
      &:hover,
      &:active,
      &:focus,
      &:focus-visible {
        border: 2px solid $primaryMain !important;
      }
    }
  }
}

.d-flex {
  display: flex !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.text-center {
  text-align: center !important;
}

.css-1jauk21-MuiTypography-root {
  line-height: 1 !important;
}

.rotate-180 {
  transform: rotate(180deg);
}

.user-avatar-container {
  position: relative;

  .avatar-progress-bar {
    position: absolute;
    top: -3px;
    left: -3px;
    transform: rotate(90deg) !important;
    circle {
      stroke-width: 2 !important;
    }
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar {
    cursor: pointer;
    width: 7px;
    height: 7px;
  }

  /* Track */
  // &::-webkit-scrollbar-track {
  // }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.6);
    z-index: 99999999999999;
  }

  &.horizontal {
    overflow-x: auto !important;
  }
  &:not(.horizontal) {
    overflow-x: hidden !important;
  }
  /* For Firefox */
  scrollbar-width: thin !important;

  overflow-y: overlay !important;
  scrollbar-color: rgba(0, 0, 0, 0.6) transparent;
}

.policy-list-container > div:not(.policy-list-container > div:last-child) {
  margin-bottom: 20px;
}

.overflow-hidden {
  overflow: hidden !important;
}

.display-none {
  display: none !important;
}
