.Manage-bank {
  width: 280px;
}
.Policies-screen {
  background-color: #f2f2f2;
  // width: 280px;
  border-radius: 6px;
}
.modal-border{
  border-radius: 6px;
}