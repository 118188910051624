.add-family {
  width: 280px;
}
.profile-icon {
  position: relative;
  z-index: -1;
}
.profile-cam-icon {
  position: absolute;
  z-index: 1;
  margin-top: 34px;
  margin-left: 18px;
}
