.policy-card {
  border: 1px solid blue;
  width: 280px;
  box-shadow: none !important;
  // border-bottom: none;
  border-radius: 4px !important;
}
.right-arrow {
  transform: rotate(180deg);
}
.separator {
  border-right-width: 15px !important;
  height: 60px;
}
.add-policy {
  width: 280px;
  height: 100%;
}
.display-inline {
  display: inline;
}
.modal-border {
  border: 1px solid #0051ba !important;
  border-radius: 2%;
}
.policy-details {
  width: 360px !important;
}
.css-jpln7h-MuiTabs-scroller {
  -webkit-flex: 0 0 auto !important;
  flex: 0.2 0 !important;

  width: 20% !important;
}
.bank-image {
  width: 60px;
  height: 60px;
  object-fit: contain;
  border: 1px solid #d7d7d7;
}
.premium-container {
  background-color: #f2f2f2 !important;
}
.nowrap-text {
  white-space: nowrap !important;
}
.finance-border {
  border: 1px solid #aaaaaa;
  border-radius: 8px;
  background-color: #d7d7d7;
}
.image-assets {
  object-fit: contain;
}
.border-box {
  border: 1px solid #0051ba !important;
  border-radius: 4px;
}
.background-container {
  background-color: #f2f2f2;
}
// .css-bz4dnt-MuiCollapse-root {
//   height: auto !important;
// }
.download_button {
  color: black !important;
  text-transform: none !important;
}
.add_button {
  display: flex !important;
  flex-direction: row-reverse !important;
}
.back-icon {
  transform: rotate(-180deg);
}
