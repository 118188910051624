.tracker-card {
  width: 279px;
  border: 1px solid #d7d7d7;
}
.display-inline {
  display: inline;
}

.border {
  width: 80%;
  margin-left: 10% !important;
  background-color: aliceblue;
}
.divider {
  width: 90%;
  margin-left: 5%;
}
.footer-card {
  width: 279px;
  border: 1px solid #d7d7d7;
  border-left: none;
  border-right: none;
}
.image-box {
  border: 1px solid #d7d7d7;
  text-align: center;
  align-items: center;
}
.timeline {
  transform: 'rotate(90deg)';
}
.timelineContentContainer {
  text-align: 'left';
}
.timelineContent {
  // display: 'inline-block';
  transform: 'rotate(-90deg)';
  text-align: 'center';
}
.timelineIcon {
  position: relative;
}
.greentick {
  position: absolute;
  margin-top: 0px;
  margin-left: 7px !important;
}
.orangedot {
  position: absolute;
  margin-top: 5px !important;
}
.content {
  padding: 0px !important;
}
.transaction-timeline-card {
  width: 277px !important;
  border: 1px solid #d7d7d7;
}
