.username-input {
  width: 294px;
  height: 52px;
  border-radius: 4px;
  font-size: 16px !important;
  color: white !important;
}

.common-login input {
  &:hover + fieldset,
  &:focus + fieldset {
    border: 2px solid orange !important;
  }
  & + fieldset {
    border: 1px solid white !important;
  }
}

.dob-input {
  font-size: 16px !important;
}
.day-month-input {
  width: 66px;
  height: 52px;
  color: white !important;
  font-size: 16px !important;
  // border: 1px solid white !important;
}
.year-input {
  width: 99px;
  height: 52px;
  color: white !important;
  font-size: 16px !important;
}
.login-footer {
  background-color: #0046a0;
}
.form {
  display: flex;
  justify-content: center;
}
.footer-text {
  color: rgba(255, 255, 255, 0.45);
  font-family: 'Quicksand', sans-serif;
}
