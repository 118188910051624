.notification-drawer-container {
  z-index: 999999 !important;
  .notification-container {
    width: 410px;
    height: 100vh;
  }

  .notification-drawer-header {
    padding: 20px 30px;
  }

  .MuiListItemText-primary {
    font-size: 18px;
  }
  .css-blntg5-MuiButtonBase-root-MuiListItemButton-root:hover {
    background-color: transparent;
  }
}
