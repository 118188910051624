.container-family {
  width: 280px;
}
.profile-pic {
  position: relative;
  z-index: -1;
}
.profile-cam {
  position: absolute;
  z-index: 1;
  margin-top: -10px;
  margin-left: 22px;
}
.profile-cam-personal {
  position: absolute;
  z-index: 1;
  margin-left: 40px;
  margin-top: -20px !important;
}
.modal {
  width: 320px;
  height: 240px;
  /* border: 1px solid #0051ba; */
  border-radius: 7px;
}
.address-details {
  width: 205px;
}
.manage-address {
  background-color: #f2f2f2;
}
.select-policy-assign {
  width: 295px;
  height: 700px;
}
.scrollable-content {
  height: calc(100% - 90px);
  overflow: auto;
}

.scrollable-content::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: transparent;
}

