.header-sub {
  background-color: 1px solid #1c49b4;
}

.box {
  justify-content: center;
}

.box-alignment {
  align-items: center;
}

.footer {
  color: #263a99;
}

.content-width {
  width: 150px;
}

.small-circle-icon {
  width: 10px;
  height: 10px;
}

.header-text-color {
  color: black;
}
.tips-box-text {
  color: #7d7d7d;
}

.text-color {
  color: #545252;
}
.email-text-color {
  color: #bb2121;
  font-weight: 500;
}
.text-alignment {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.content-box {
  background-color: #e6f0fd;
}
.info {
  font-size: 14px;
  font-weight: 400;
}
.line-color {
  border: 1px solid #545252;
  height: 100px;
}
.bod {
  border-left: 1px solid black;
}
.space {
  margin-right: 50px !important;
}
.space-left {
  margin-left: 50px !important;
}
.box-line {
  margin-bottom: 50px;
}
.box-border-line {
  border: 1px solid red;
}
.both-line {
  border: 1px solid blue;
}

@media (max-width: 320px) {
  .content-merge {
    display: block;
    width: 100%;
  }
  .email-text-res {
    width: 80%;
    display: block;
  }
  .font {
    font-size: 14;
  }
}
@media (max-width: 500px) {
  .line {
    display: none;
  }
}
@media (max-width: 450px) {
  .space {
    margin-right: 0px !important;
  }
  .space-left {
    margin-left: 0px !important;
  }
  .bod {
    border-left: none;
  }
}
