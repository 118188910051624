.auth-layout-container {
  height: 100vh;
  overflow-x: auto;
}

.app-layout-container {
  .company-title {
    font-size: 24px;
    box-sizing: border-box;
    font-weight: 600;
    text-align: left;
    line-height: normal;
  }

  .tool-bar-container {
    min-height: auto !important;
  }
}

.left-menu-container {
  width: 100%;
  margin-left: 40px !important;
  .menu-item-container {
    margin-bottom: 16px !important;
  }
}
